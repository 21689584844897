/** @jsx jsx */

import { Box, jsx, Text } from 'theme-ui';
import SanityBlockContent from '@sanity/block-content-to-react';

const BlockRenderer = (props) => {
  if (props.children.join('').length === 0) return null;

  const { style = 'normal' } = props.node;

  if (style === 'normal') return <Text as="p">{props.children}</Text>;

  if (style === 'h5')
    return (
      <Text as="p" variant="small">
        {props.children}
      </Text>
    );

  if (style === 'h1')
    return (
      <Text as="p" variant="f5_hero_copy" className="hero_copy">
        {props.children}
      </Text>
    );

  if (style === 'h2')
    return (
      <Text as="h2" variant="h2">
        {props.children}
      </Text>
    );
  if (style === 'h3')
    return (
      <Text as="h3" variant="h3">
        {props.children}
      </Text>
    );
  if (style === 'h4')
    return (
      <Text as="h4" variant="h4">
        {props.children}
      </Text>
    );

  // if (/^h\d/.test(style)) {
  //   const level = style.replace(/[^\d]/g, '');
  //   return React.createElement(style, { className: `heading-${level}` }, props.children);
  // }

  // Fall back to default handling
  return SanityBlockContent.defaultSerializers.types.block(props);
};

const Container = ({ as = 'section', children }) => {
  return (
    <Box
      as={as}
      sx={{
        '& > ol': {
          paddingLeft: '2em',
          '& > li': {
            marginTop: [6],
            marginBottom: [6],
          },
        },
        '& > ul': {
          paddingLeft: '2em',
          '& > li': {
            marginTop: [6],
            marginBottom: [6],
          },
        },
        '& > p, & > h4, & > ol, & > ul': {
          marginBottom: [9],
          maxWidth: '55ch',
        },
        '& > h2, & > h3': {
          maxWidth: '35ch',
        },
        '& .hero_copy': {
          paddingTop: [10, 12],
          marginBottom: [10, 12],
          maxWidth: '25ch',
        },
        '& > h2': {
          marginTop: [10, 12],
          marginBottom: [10, 12],
        },
        '& > h3': {
          marginTop: [10, 12],
          marginBottom: [9],
        },
        '& > h4': {
          marginTop: [10, 12],
          marginBottom: [6],
        },
        '& > *:first-child': {
          paddingTop: [0],
          marginTop: [0],
        },
        '& > *:last-child': {
          marginBottom: [0],
          paddingBottom: [0],
        },
        '& > *:only-child': {
          paddingTop: [0],
          marginTop: [0],
          marginBottom: [0],
        },
      }}
    >
      {children}
    </Box>
  );
};

const serializers = {
  container: Container,

  types: {
    block: BlockRenderer,
    code: (props) => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    ),
  },
};

export const BlockContent = ({ blocks }) => {
  return (
    <SanityBlockContent
      blocks={blocks}
      serializers={serializers}
      renderContainerOnSingleChild={true}
    />
  );
};
